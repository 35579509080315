import React, { Fragment, useRef, useState } from "react";
import { IoIosCloseCircle, IoIosCheckmarkCircle } from "react-icons/io";

import Layout from "../components/layout";
import heroBg from "../images/hero-bg2.jpg";
import heroBg3 from "../images/hero-bg3.jpg";
import SEO from "../components/seo";

const License = () => {
  return (
    <>
      <Layout>
        <SEO
          keywords={[`mockup`, `video`, `maker`, `editor`, `online`, `stock`, `app`, `device`, `phone`, `tablet`, `desktop`, `screen`, `replacement`, `iphone`, `macbook`, `laptop`, `samsung`]}
          title="License"
        />

        <section className="bg-gradient-to-l from-blue-500 to-blue-700 relative bg-cover" style={{ backgroundImage: `url('${heroBg}')`, backgroundPosition: '100%' }}>

          <div className="container mx-auto lg:mt-0 pt-28 relative z-10">
            <div className="mx-auto w-full px-6 md:px-0 md:w-4/5 lg:w-2/3">
              <h1 className="text-center text-white font-semibold text-3xl md:text-5xl leading-tight mb-2">License</h1>
              <h2 className="w-full px-0 md:px-6 md:w-3/4 mx-auto text-center text-base md:text-xl text-indigo-200 pb-6">Straight-forward licensing for your videos</h2>
            </div>
          </div>

          {/* <div className="bg-svg-shape-1"></div> */}
          <div className="relative hidden lg:block">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290" preserveAspectRatio="xMinYMid slice">
              <path fill="#edf2f7" fillOpacity="1" d="M0,288L1440,160L1440,320L0,320Z"></path>
            </svg>
          </div>
        </section>

        <section className="lg:absolute z-10 w-full bg-gray-200 lg:bg-transparent pt-12 lg:pt-0" style={{top: 275}}>
          <div className="container mx-auto pb-18 md:pb-28 px-24">

            <div className="bg-white shadow-lg rounded-lg py-8 px-10 text-gray-800">
            <p className="mb-6 text-gray-700">By purchasing a video, we will grant you a royalty-free, perpetual, irrevocable, non-exclusive, non-transferable license to use the video generated by our platform in a single project.</p>
            <div className="flex mb-5">
              <div className="w-full lg:w-1/2 pr-3 border-r">
                <h2 className="text-2xl font-bold mb-3 border-b pb-2">You can</h2>
                <div className="flex flex-col space-y-4">
                  <div className="flex items-start">
                    <div><IoIosCheckmarkCircle className="inline-flex w-6 h-full text-green-500 mr-1.5" /></div>
                    <div>Use in a commercial or personal project.</div>
                  </div>
                  <div className="flex items-start">
                    <div><IoIosCheckmarkCircle className="inline-flex w-6 h-full text-green-500 mr-1.5" /></div>
                    <div>Use in a free or paid advertisement. E.g on your website or on social media etc.</div>
                  </div>
                  <div className="flex items-start">
                    <div><IoIosCheckmarkCircle className="inline-flex w-6 h-full text-green-500 mr-1.5" /></div>
                    <div>Use multiple times in a single project. E.g if you're creating cutdown videos of a full-length promo.</div>
                  </div>
                  <div className="flex items-start">
                    <div><IoIosCheckmarkCircle className="inline-flex w-6 h-full text-green-500 mr-1.5" /></div>
                    <div>Use without attribution or credit to the author.</div>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-1/2 pl-3">
                <h2 className="text-2xl font-bold mb-3 border-b pb-2">You can't</h2>
                <div className="flex flex-col space-y-4">
                  <div className="flex items-start">
                    <div><IoIosCloseCircle className="inline-flex w-6 h-full text-red-500 mr-1.5" /></div>
                    <div>Use in a TV broadcast, movie theater or VOD project. <a className="text-indigo-600" href="mailto:hello@editsquare.com">Contact us</a> if this is required.</div>
                  </div>
                  <div className="flex items-start">
                    <div><IoIosCloseCircle className="inline-flex w-6 h-full text-red-500 mr-1.5" /></div>
                    <div>Use harmful, pornographic or offensive material in your video.</div>
                  </div>
                  <div className="flex items-start">
                    <div><IoIosCloseCircle className="inline-flex w-6 h-full text-red-500 mr-1.5" /></div>
                    <div>Use the video as a basis for merchandising, e.g printing on t-shirts.</div>
                  </div>
                  <div className="flex items-start">
                    <div><IoIosCloseCircle className="inline-flex w-6 h-full text-red-500 mr-1.5" /></div>
                    <div>Redistribute or sell the video. E.g on other stock platforms.</div>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-4"><a className="text-indigo-600" href="https://editsquare.com/terms?ref=mockupclips" target="_blank">Click here</a> to read our full license agreement and terms.</p>
            <p className="mt-1">If you are unsure whether the license covers your particular scenario, feel free to <a className="text-indigo-600" href="mailto:hello@editsquare.com" target="_blank">contact us.</a></p>
            </div>
          </div>
        </section>

        <section className="bg-gray-200 hidden lg:block lg:h-144 xl:h-96">
        </section>

      </Layout>
    </>
  );
}

export default License;
